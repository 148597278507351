import React from "react";
import Container from "../../components/chat-channel/Container";
import Layout from "../../components/chat-channel/Layout";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import { RatingGoTo } from "../conversational-ai-platform";
import { useMedia } from "use-media";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";

const bannerImage = require("../../assets/img/feature/home/client_logo_mar24.svg");
const bannerImageMobile = require("../../assets/img/feature/home/client_logo_mar24_mob.svg");

const tick = require("../../assets/images/onboarding/onboarding_tick.svg");

const OgImage = require("../../assets/img/home_page_assist/home_header_assist.png");
const FeatureSlack = require("../../assets/images/features_images/features_slack.png");
const FeatureTeams = require("../../assets/images/features_images/features_teams.png");
const FeatureChat = require("../../assets/images/features_images/features_chat.png");

export default function DemoPage() {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Free Conversational AI Chatbot Demo | Workativ Assistant Demo"
        description="Free Conversational AI Chatbot Demo. Learn how workativ can transform your workplace support using conversational ai and workflow automation in minutes."
        keywords={["workativ"]}
        ogTitle="Free Conversational AI Chatbot Demo | Workativ Assistant Demo"
        ogImage={OgImage}
        ogDescription="Free Conversational AI Chatbot Demo. Learn how workativ can transform your workplace support using conversational ai and workflow automation in minutes."
      />
      <Container>
        <Layout backgroundColor={"roi_bg"} logoFor="ASSISTANT">
          <DemoPageContentAndForm formName={"DemoForm"}>
            <DemoPageContentAndForm.Header>
              Automate Your Workplace Support in Minutes
            </DemoPageContentAndForm.Header>
            <DemoPageContentAndForm.Content>
              Deliver automated and lightning fast employee support with our
              4-in-1 dynamic support automation platform.
            </DemoPageContentAndForm.Content>
            <DemoPageContentAndForm.H6>
              Our live demo covers:
            </DemoPageContentAndForm.H6>
            <DemoPageContentAndForm.Ul isMobile={isMobile}>
              {liContent.map((li) => (
                <DemoPageContentAndForm.Li isMobile={isMobile}>
                  {li.content}
                </DemoPageContentAndForm.Li>
              ))}
            </DemoPageContentAndForm.Ul>
          </DemoPageContentAndForm>
          <div
            className="float-left demo-testimonials-parent"
            style="width: 100%;"
          >
            <div className="container">
              <h5 className="text-center margin-bottom-20 font-section-normal-text-medium">
                Join hundreds of industry leaders
              </h5>{" "}
              {isMobile ? (
                <img loading="lazy" className="" src={bannerImageMobile} />
              ) : (
                <img loading="lazy" className="" src={bannerImage} />
              )}
            </div>
          </div>
          <RatingGoTo />
          <RequestForm isFooterForm={true} />
          {/* {isMobile ? null : <OnScrollPopup />} */}
          {/* <GoToPopup />
          <CookiesPoup /> */}
        </Layout>
      </Container>
    </>
  );
}

const liContent = [
  {
    content:
      "How chatbot auto-resolves 80% of employee IT & HR queries in seconds",
  },
  {
    content:
      "How to use App Workflows in chatbot for end-to-end workflow automation",
  },
  {
    content:
      "Integrate bot with ITSM platforms, HR apps, and 80+ apps instantly",
  },
  {
    content: "Knowledge AI - build your own ChatGPT for your Knowledge Base",
  },
  {
    content:
      "AI-powered Shared Live Inbox for agents to provide live chat support",
  },
  {
    content: "Learn cost savings from case studies",
  },
];

DemoPageContentAndForm.Header = ({ children }) => {
  return (
    <h1 className="font-section-sub-header-bold-demo color-black">
      {children}
    </h1>
  );
};
DemoPageContentAndForm.Content = ({ children }) => {
  return (
    <p className="font-section-normal-text-testimonials line-height-18 margin-bottom-20">
      {children}
    </p>
  );
};
DemoPageContentAndForm.H6 = ({ children }) => {
  return (
    <h6 className="font-section-normal-text-testimonials-medium color-black">
      {children}
    </h6>
  );
};
DemoPageContentAndForm.Ul = ({ children, isMobile }) => {
  return (
    <ul
      className={`list-style-type-none float-left w-100 pl-0 ${
        isMobile ? "mb-2" : "mb-3"
      }`}
    >
      {children}
    </ul>
  );
};
DemoPageContentAndForm.Li = ({ children, isMobile }) => {
  return (
    <div
      className={`d-flex gap-10-px mb-3 ${
        isMobile ? "align-items-start" : "align-items-start"
      } `}
    >
      <img
        src={tick}
        alt="Image description"
        style={{
          position: isMobile ? "relative" : "relative",
          top: isMobile ? "2px" : "1px",
          width: isMobile ? "14px" : "18px",
        }}
      />
      <li className="font-section-small-text">{children}</li>
    </div>
  );
};

DemoPageContentAndForm.Form = ({ children }) => {
  return <>{children}</>;
};

export function DemoPageContentAndForm({ children, formName }) {
  const isMobile = useMedia({ maxWidth: "520px" });
  return (
    <section className="skit_contactus sign_up-form_us demo_page roi_bg layout-assitant-demo">
      <div className="container signup_form">
        {" "}
        . . . .
        <div className="beta_change_form demo-grid-box">
          <div className="demo-grid-box-left">
            <div className="roi-form roi-form-demo">
              <h1 className="font-section-sub-header-normal color-black">
                Book a Demo
              </h1>
            </div>
            {children}
            <div className="d-flex width-fit-content gap-10 mt-4">
              <div className="d-flex width-fit-content">
                <img
                  loading="lazy"
                  class="slack_feature_icon_onboarding mr-2"
                  src={FeatureSlack}
                ></img>
                <h3
                  className={`font-section-small-text-medium-apps color-light-black mb-0 ${
                    isMobile ? "pt-0" : "pt-1"
                  }`}
                >
                  Slack
                </h3>
              </div>

              <div className="d-flex width-fit-content">
                <img
                  loading="lazy"
                  class="teams_feature_icon_onboarding mr-2"
                  src={FeatureTeams}
                ></img>
                <h3
                  className={`font-section-small-text-medium-apps color-light-black mb-0 ${
                    isMobile ? "pt-0" : "pt-1"
                  }`}
                >
                  Teams
                </h3>
              </div>
              <div className="d-flex width-fit-content">
                <img
                  loading="lazy"
                  class="chat_feature_icon_onboarding mr-2"
                  src={FeatureChat}
                ></img>
                <h3
                  className={`font-section-small-text-medium-apps color-light-black mb-0 ${
                    isMobile ? "pt-0" : "pt-1"
                  }`}
                >
                  Chat Widget
                </h3>
              </div>
            </div>
          </div>
          <div className="demo-grid-box-right">
            {formName === "DemoForm" ? <DemoForm /> : null}
          </div>
        </div>
      </div>
    </section>
  );
}

function DemoForm() {
  return (
    <>
      <div className="demo_page_form assitants-demo-page">
        <iframe
          src="https://outlook.office365.com/owa/calendar/Bookademo@workativ.com/bookings/"
          style={{ border: "none", height: "500px", width: "100%" }}
          scrolling="yes"
        ></iframe>
      </div>
    </>
  );
}
